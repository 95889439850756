import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"

import ScrollOut from "scroll-out"
import Contributors from "../../../components/athena/contributors"
import MoreArticles from "../../../components/athena/moreArticlesJoy"

import MarqueeText from "../../../components/marqueeText"

import Layout, { athenaTheme } from "../../../components/layout"
import PreFooter from "../../../components/athena/preFooter"
import ScrollProgressBar from "../../../components/scrollProgressBar"
import bkg from "../../../images/athena-04-resilience/03-vocab-of-resilience/bkg.jpg"
import {TwitterIcon, TwitterShareButton, LinkedinIcon, LinkedinShareButton, FacebookShareButton, FacebookIcon} from 'react-share';
import QuoteBackground from "./article-03/pullQuoteBackground"

import styles from "./vocab.module.scss"

let theme = {
  ...athenaTheme,
  background: styles.background,
  foreground: styles.foreground,
  headerPosition: styles.position,
}

const Article = ({ data }) => {
  const article = data.craft.article
  const seo = article.seomatic
  //const coverImages = article.coverImages ? article.coverImages[0] : null

  let [heroFade, setHeroFade] = useState(true)
  useEffect(() => {
    setHeroFade(0)
    let timeoutId = setTimeout(() => {
      setHeroFade(1)
      timeoutId = setTimeout(() => {
        setHeroFade(2)
          timeoutId = setTimeout(() => {
          setHeroFade(3)
            timeoutId = setTimeout(() => {
            setHeroFade(4)
          }, 250)
        }, 500)
      }, 250)
    }, 250)
    return () => clearTimeout(timeoutId)
  }, [])

  let group = index => ({
    style: {
      transition: "opacity .6s ease-in-out",
      opacity: heroFade >= index ? 1 : 0.0125,
    },
  })

  useEffect(() => {
    let so = ScrollOut({
      offset: 200,
      cssProps: {
        viewportY: true,
      },
    })
    return so.teardown
  }, [])

  return (
    <Layout seo={seo} mode="athena" theme={theme} {...group(0)}>
      <Helmet>
        <body className={`athena ${styles.body}`}/>
      </Helmet>
      <article>
        <ScrollProgressBar />
        <div className={styles.article} {...group(2)}>
          <div className={styles.hero} style={{backgroundImage: `url(${bkg})`}} >
            <div className={styles.heroContainer}>
              <div className={styles.box} data-scroll></div>
            </div>
            <div className={styles.issue} {...group(3)}>
              <p className="a-h-5">Issue 04 / Exploring Joy, Reimagining Resilience </p>
            </div>
            <div className={styles.title} {...group(4)}>
              <h1 className="a-h-display">{article.title}</h1>
            </div>
          </div>
          <div className={`${styles.articleBodyTop}`}>
            <div className="wrapper">
              <div className={styles.readingTime}>
                <p className={styles.reading}>5 min reading time</p>
                <p className={styles.readingMobile}>5 min read</p>
                <div className={styles.socialContainer}>
                  <p className={styles.share}>Share —</p>
                  <TwitterShareButton
                  url={'https://zeusjones.com/athena/articles/the-vocabulary-of-joyful-resilience/'}
                  title={article.title}
                  className={styles.shareButtons}
                  via = 'zeusjones'
                  >
                    <TwitterIcon />
                  </TwitterShareButton>
                
                  <LinkedinShareButton
                  url={'https://zeusjones.com/athena/articles/the-vocabulary-of-joyful-resilience/'}
                  title={article.title}
                  className={styles.shareButtons}
                  via = 'zeusjones'
                  >
                    <LinkedinIcon />
                  </LinkedinShareButton>
              
                  <FacebookShareButton
                  url={'https://zeusjones.com/athena/articles/the-vocabulary-of-joyful-resilience/'}
                  title={article.title}
                  className={styles.shareButtons}
                  via = 'zeusjones'
                  >
                    <FacebookIcon />
                  </FacebookShareButton>
                </div>
              </div>
              <div className="columns is-desktop">
                <div className="column is-10-tablet is-11-desktop">
                  <h2 className={`${styles.introHeading}`}><span>R</span>eimagi<span>n</span>i<span>n</span>g <span>R</span>esili<span>e</span>n<span>c</span>e sta<span>r</span>ts with being in<span>q</span>ui<span>s</span>itive about <span>T</span>he <span>W</span>ord<span>s</span> w<span>e</span> use <span>t</span>o de<span>f</span>ine i<span>t.</span></h2>
                  
                </div>
              </div>
              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-8-desktop">
                  <div className="user-content-athena">
                    <p>Military-inspired terms pervade business and career jargon. Such a vocabulary normalizes the idea that it is ok—or even necessary—to endure pain and hardship in order to ensure the success of each 'operation' falling under our duty. Using this frame of reference, every mundane task sounds mission-critical and inevitably becomes connected to a 'greater goal' we feel compelled to serve — demanding superhuman dedication, grit, and toughness for prolonged periods of time.</p>
                  </div>
                </div>
              </div>
            </div>
            {/* Marquee */}
            <div className={styles.scroller}>
              <div className={styles.list}>
                <ol>
                  <li>
                    <MarqueeText dir={-1}>
                      <span className={`${styles.listItem}`}>Experiences</span>
                      <span className={`${styles.listItem}`}>In The Field</span>
                      <span className={`${styles.listItem} ${styles.yellow}`}>Deploy</span>
                      <span className={`${styles.listItem}`}>Align</span>
                      <span className={`${styles.listItem} ${styles.forestGreen}`}>Execute</span>
                      <span className={`${styles.listItem}`}>Headhunters</span>
                    </MarqueeText>
                  </li>
                  <li>
                    <MarqueeText>
                      <span className={`${styles.red} ${styles.listItem}`}>Strategy</span>
                      <span className={`${styles.listItem}`}>Engagement</span>
                      <span className={`${styles.listItem} ${styles.brightPurple}`}>Approach</span>
                      <span className={`${styles.listItem}`}>Altitude</span>
                      <span className={`${styles.listItem} ${styles.teal}`}>Price Wars</span>
                    </MarqueeText>
                  </li>
                  <li>
                    <MarqueeText dir={-1}>
                      <span className={`${styles.listItem}`}>Lines of Attack</span>
                      <span className={`${styles.listItem} ${styles.blue}`}>Landed on an idea</span>
                      <span className={`${styles.listItem}`}>Campaigns</span>
                      <span className={`${styles.listItem} ${styles.orange}`}>Groundwork</span>
                      <span className={`${styles.listItem}`}>War Rooms</span>
                    </MarqueeText>
                  </li>
                  <li>
                    <MarqueeText>
                      <span className={`${styles.listItem} ${styles.forestGreen}`}>Daily Grind</span>
                      <span className={`${styles.listItem}`}>Intelligence</span>
                      <span className={`${styles.listItem} ${styles.green}`}>Logistics</span>
                      <span className={`${styles.listItem}`}>Phases</span>
                      <span className={`${styles.listItem} ${styles.red}`}>Dominance</span>
                    </MarqueeText>
                  </li>

                  <li>
                    <MarqueeText>
                      <span className={`${styles.listItem} ${styles.orange}`}>Planning</span>
                      <span className={`${styles.listItem}`}>Bleeding Edge</span>
                      <span className={`${styles.listItem} ${styles.purple}`}>Targets</span>
                      <span className={`${styles.listItem}`}>Escalate</span>
                      <span className={`${styles.listItem} ${styles.navy}`}>Superiority</span>
                    </MarqueeText>
                  </li>

                  <li>
                    <MarqueeText dir={-1}>
                      <span className={`${styles.listItem} ${styles.teal}`}>Kick-Ass Product</span>
                      <span className={`${styles.listItem}`}>Shoot For</span>
                      <span className={`${styles.listItem} ${styles.blue}`}>Laser-Sharp</span>
                      <span className={`${styles.listItem}`}>Killer Idea</span>
                      <span className={`${styles.listItem} ${styles.green}`}>Aggressive Pricing</span>
                    </MarqueeText>
                  </li>

                  <li>
                    <MarqueeText>
                      <span className={`${styles.listItem} ${styles.red}`}>Rapid-fire</span>
                      <span className={`${styles.listItem}`}>Divide & Conquer</span>
                      <span className={`${styles.listItem} ${styles.yellow}`}>Marching Orders</span>
                      <span className={`${styles.listItem}`}>Sales force</span>
                      <span className={`${styles.listItem} ${styles.brightPurple}`}>Sacrifice</span>
                    </MarqueeText>
                  </li>
                </ol>
              </div>
            </div>
            
            <div className="wrapper">
              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-8-desktop">
                  <div className="user-content-athena">
                    <p>Chronic stress, emotional strain, and fatigue are NOT what our brains and bodies are designed to endure, and certainly not what we need to prosper physically, emotionally, or creatively. Our biology carries a fight or flight 'reptilian' response to protect us from life-or-death situations. When work conversations trigger such a response, tapping into our higher-level cognitive functions for empathetic and innovative thinking becomes more and more difficult.</p>
                    <p>We all deserve a new vocabulary of resilience. One where joy and creativity become our compass—guiding us out of the crisis we're experiencing and away from the toxic obligations that have compromised so much of our wellbeing.</p>
                    <p>Let's manifest this new reality one definition at a time—making room for novel behaviors, kinder intentions, and more mindful creative practices.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${styles.quote}`}>
              <div className={styles.quoteContainer}>
                <QuoteBackground/>
                  <div className={styles.shareQuote}>
                    <div className={styles.quoteText}>
                      <h2 className={`${styles.h2}`} style={{margin: '0'}}>
                        {/* We All Deserve */}
                        <span className={styles.twoHundred}></span>
                        <span><b>W</b>e</span>
                        <span>all</span>
                        <span>de<b>s</b>e<b>r</b>ve</span>
                        
                        <span className={styles.fullWidth} style={{width: '100%'}}></span>

                        {/* A New Vocabulary */}
                        <span>a</span>
                        <span><b>n</b>ew</span>
                        <span><b>v</b>oc<b>a</b>bular<b>y</b></span>
                        
                        <span className={styles.fullWidth} style={{width: '100%'}}></span>
                        
                        {/* Of Resilience */}
                        <span><em>o</em>f</span>
                        <span><b>r</b>esili<b>e</b>n<b>c</b>e.</span>
                      </h2>
                      <h2 className={styles.h2} style={{margin: '0'}}>
                        <span className={styles.oneFifty}></span>
                        {/* One where */}
                        <span>One</span>
                        <span><b>w</b>here</span>
                        
                        <span className={styles.fullWidth} style={{width: '100%'}}></span>
                        
                        {/* Joy And Creativity*/}
                        <span className={styles.oneHundred}></span>
                        <span>J<em>o</em>y</span>
                        <span>and</span>
                        <span>Crea<b>t</b>ivi<b>t</b>y</span>

                        <span className={styles.fullWidth} style={{width: '100%'}}></span>

                        {/*  Become Our Compass*/}
                        <span>beco<b>m</b>e</span>
                        <span>ou<b>r</b></span>
                        <span>co<b>m</b>pass</span>
                      </h2>
                      <h2 className={styles.h2} style={{margin: '0'}}>
                        {/* Guiding Us Out */}
                        <span>— guidi<b>n</b>g</span>
                        <span>u<b>s</b></span>
                        <span>ou<b>t</b></span>

                        <span className={styles.fullWidth} style={{width: '100%'}}></span>

                        {/* Of the crisis we're */}
                        <span className={styles.seventyFive}></span>
                        <span><em>o</em>f</span>
                        <span>the</span>
                        <span>c<b>r</b>isis</span>
                        <span>we're</span>

                        <span className={styles.fullWidth} style={{width: '100%'}}></span>
                        
                        {/* Experiencing  And */}
                        <span>expe<b>r</b>ien<b>c</b>ing</span>
                        <span>and</span>
                        
                        <span className={styles.fullWidth} style={{width: '100%'}}></span>

                        {/* Away from the toxic */}
                        <span>a<b>w</b>ay</span>
                        <span>fr<em>o</em>m</span>
                        <span>the</span>
                        <span>t<em>o</em>xic</span>

                        <span className={styles.fullWidth} style={{width: '100%'}}></span>

                        {/* Obligations That */}
                        <span>obliga<b>t</b>ions</span>
                        <span>that</span>

                        <span className={styles.fullWidth} style={{width: '100%'}}></span>

                        {/* Have Compromised */}
                        <span>hav<b>e</b></span>
                        <span>comp<b>r</b>omised</span>

                        <span className={styles.fullWidth} style={{width: '100%'}}></span>
                        
                        {/* So Much of our */}
                        <span className={styles.oneHundred}></span>
                        <span><b>s</b>o</span>
                        <span>mu<b>c</b>h</span>
                        <span>of</span>
                        <span>ou<b>r</b></span>

                        <span className={styles.fullWidth} style={{width: '100%'}}></span>

                        {/* Wellbeing */}
                        <span><b>w</b>ellbei<b>n</b>g.</span>

                        <TwitterShareButton
                        url={"https://zeusjones.com/athena/articles/the-vocabulary-of-joyful-resilience/"}
                        title="We all deserve a new vocabulary of resilience. One where Joy and Creativity become our compass—guiding us out of the crisis we're experiencing and away from the toxic obligations that have compromised so much of our wellbeing."
                        className={styles.articleShareButton}
                        via = "zeusjones"
                        >
                          <span>Tweet <TwitterIcon /></span>
                        </TwitterShareButton>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div className={`${styles.articleBodyBottom}`}>
            
            <div className={`${styles.bgGreen} ${styles.firstRow} ${styles.borderBottom}`}>
              <div className={`wrapper`}>
                <div className={`${styles.columns}`}>
                  <div className={styles.columnLeft}>
                    <h2 className={styles.h2}>J<em>o</em>yful <em>R</em>esili<span>e</span>n<span>c</span>e I<span>n</span>dex</h2>
                  </div>
                  <div className={styles.columnRight}>
                    <div className={styles.height}></div>
                    <p>Introducing a collection of freshly wordsmithed concepts to describe how we can foster and normalize a practice of regenerative joy and creativity for greater resilience in life, work, and relationships.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className={`${styles.bgGreen} ${styles.borderBottom}`}>
              <div className="wrapper">
                <div className={`${styles.columns}`}>
                  <div className={styles.columnLeft}>
                    <div className={styles.mobileLeftColumn}>
                      <h2 className={styles.h3}><em>O</em>ti<span>u</span>m L<span>e</span>ave</h2>
                      <h4>[oh-ti-oom leev]</h4>
                    </div>
                    <div>
                      <button className="a-h-5">Corporate Policy</button>
                    </div>
                  </div>
                  <div className={styles.columnRight}>
                    <p>Paid time off granted to aid the replenishment of an employee's full creative potential. Highly encouraged after mentally, emotionally, or physically enduring a particularly difficult time It's typically intended for leisurely learning a new skill, to recharge in contemplation, or to pursue creative exploration without feeling concerned about the outcome.</p>
                  </div>
                </div>
              </div>
            </div>
            
            <div className={`${styles.bgGreen} ${styles.borderBottom}`}>
              <div className="wrapper">
                <div className={`${styles.columns}`}>
                  <div className={styles.columnLeft}>
                    <div className={styles.mobileLeftColumn}>
                      <h3 className={styles.h3}><em>O</em>ti<span>u</span>m T<span>e</span>am<span>t</span>ime</h3>
                      <h4>[oh-ti-oom teem-tahym]</h4>
                    </div>
                    <div>
                      <button className="a-h-5">Warm Up & Rekindling Technique</button>
                    </div>
                  </div>
                  <div className={styles.columnRight}>
                    <p>Indulging in a moment of digression and collective playtime in order to supercharge the team's capability to overcome potential challenges and setbacks.</p>
                    <p>It can be restorative in nature — to replenish optimal levels of trust, optimism, and excitement after a particularly frustrating series of meetings. Or it can be propaedeutic, similar to a 'warm up' exercise — to awaken the imaginative and creative muscles in preparation for a session with many difficult ideas to unravel.</p>
                  </div>
                </div>
              </div>
            </div>
            
            <div className={`${styles.bgGreen} ${styles.borderBottom}`}>
              <div className="wrapper">
                <div className={`${styles.columns}`}>
                  <div className={styles.columnLeft}>
                    <div className={styles.mobileLeftColumn}>
                      <h3 className={styles.h3}>J<em>o</em>y - Co<span>m</span>plish<span>e</span>d</h3>
                      <h4>[joi-kom-plisht]</h4>
                    </div>
                    <div>
                      <button className="a-h-5">Success Metric</button>
                    </div>
                  </div>
                  <div className={styles.columnRight}>
                    <p>The intentional act of measuring a company's or a person's level of accomplishment through the intensity and persistence of joyful feelings generally associated with their routine work practices.</p>
                  </div>
                </div>
              </div>
            </div>
            
            <div className={`${styles.bgGreen} ${styles.borderBottom}`}>
              <div className="wrapper">
                <div className={`${styles.columns}`}>
                  <div className={styles.columnLeft}>
                    <div className={styles.mobileLeftColumn}>                      
                      <h3 className={styles.h3}>J<em>o</em>y Du<span>t</span>y</h3>
                      <h4>[joi-doo-tee]</h4>
                    </div>
                    <div>
                      <button className="a-h-5">Personal & Business Mantra</button>
                    </div>
                  </div>
                  <div className={styles.columnRight}>
                    <p>Consciously prioritizing joy and abundance of playful explorations as primary duties for a company, an individual, or a community. In corporate settings, committing to a shared Joy Duty sets the stage for collective business decisions revolving around questions such as:</p>
                    <ul>
                      <li>
                        <p>What is the 'Joy Duty' that my brand can uniquely validate, normalize, and nourish?</p>
                      </li>
                      <li>
                        <p>How can commitment to a bold 'Joy Duty' help my company attract and retain the very best talent along with the most rewarding and expansive wealth of customers?</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            
            <div className={`${styles.bgGreen} ${styles.borderBottom}`}>
              <div className="wrapper">
                <div className={`${styles.columns}`}>
                  <div className={styles.columnLeft}>
                    <div className={styles.mobileLeftColumn}>
                      <h3 className={styles.h3}><span>A</span>ffini<span>t</span>y Own<span>e</span>rship M<em>o</em>del</h3>
                      <h4>[uh-fin-itee ow-nuh-shuhp mo-dl]</h4>
                    </div>
                    <div>
                      <button className="a-h-5">Burnout Prevention Technique</button>
                    </div>
                  </div>
                  <div className={styles.columnRight}>
                    <p>A new model for idea ownership that maximizes an idea's potential and minimizes creative fatigue. Those who organically demonstrate the most ease and interest in pushing an idea further are encouraged to own it—prioritizing elective affinities and creative momentum over traditional authorship rights and duties. This model can also be utilized to ensure that specific affinity groups are empowered to take ownership of an idea that would be greatly be enhanced by their unique life experiences.</p>
                  </div>
                </div>
              </div>
            </div>
            
            <div className={`${styles.bgGreen}`}>
              <div className="wrapper">
                <div className={`${styles.columns}`}>
                  <div className={styles.columnLeft}>
                    <div className={styles.mobileLeftColumn}>
                      <h3 className={styles.h3}>Play Adv<span>o</span>cat<span>e</span>s</h3>
                      <h4>[pley ad-vuh-kits]</h4>
                    </div>
                    <div>
                      <button className="a-h-5">Anti-Burnout Leadership Role</button>
                    </div>
                  </div>
                  <div className={styles.columnRight}>
                    <p>A leadership role that focuses on cultivating, protecting, and empowering the natural conditions in which individuals can creatively function at their best: a state of joyful playfulness. In these conditions, contributors are empowered to question, re-imagine, and explore alternative solutions without the pressure of delivering immediate, measurable results.</p>
                  </div>
                </div>
              </div>
            </div>
            
            <div className={`${styles.bgGreen}`}>
            <Contributors
              style={{marginTop: '0'}}
              zeus={article.zeusContributors}
              className={styles.bgGreen}
            />
            <MoreArticles className={styles.moreFrom} id={article.id} issue={article.issue[0].id} />
            </div>
            <PreFooter />
          </div>
        </div>

      </article>
      
    </Layout>
  )
}

export default Article

export const query = graphql`
  query($slug: String!) {
    craft {
      article: entry(slug: [$slug], section: "athenaArticles") {
        id
        title
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_athenaArticles_athenaArticleCustom_Entry {
          articleSubTitle
          issue {
            id
            title
          }
          coverImages {
            ... on Craft_coverImages_BlockType {
              ratio_16x9 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
              ratio_4x3 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
            }
          }
          zeusContributors {
            id
            title
            uri
          }
          otherContributors {
            ... on Craft_otherContributors_TableRow {
              name
              website
            }
          }
          athenaCta {
            ...AthenaCta
          }
        }
      }
    }
  }
`
