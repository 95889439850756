import React, {useEffect} from "react"
import styles from "../vocab.module.scss"
import ScrollOut from "scroll-out"


import TopLeft from "../../../../images/athena-04-resilience/03-vocab-of-resilience/top-left.png"
import TopCenter from "../../../../images/athena-04-resilience/03-vocab-of-resilience/top-center.png"
import TopRight from "../../../../images/athena-04-resilience/03-vocab-of-resilience/top-right.png"
import LeftMiddle from "../../../../images/athena-04-resilience/03-vocab-of-resilience/left-middle.png"
import BottomLeft from "../../../../images/athena-04-resilience/03-vocab-of-resilience/bottom-left.png"
import BottomCenter from "../../../../images/athena-04-resilience/03-vocab-of-resilience/bottom-center.png"
import BottomRight from "../../../../images/athena-04-resilience/03-vocab-of-resilience/bottom-right.png"

export default function QuoteBackground() {

  useEffect(() => {
    let so = ScrollOut({
      cssProps: {
        viewportY: true,
      }
    })
    return so.teardown
  }, [])

  return (
    <div className={styles.pullQuoteBackground}>
      <div className={styles.imageContainerTop}> 
        <img src={TopLeft} data-scroll className={`${styles.topLeft}`}  alt="Top Left Background Shape" />
        <img src={TopCenter} data-scroll className={`${styles.topCenter}`} alt="Top Center Background Shape" />
        <img  src={TopRight} data-scroll className={`${styles.topRight}`} alt="Top Right Background Shape" />
      </div>
      <div className={styles.imageContainerMiddle}>
        <img src={LeftMiddle} data-scroll className={styles.leftMiddle} alt="Left Middle Background Shape" />
      </div>
      <div className={styles.imageContainerBottom}>
        <img src={BottomLeft} data-scroll className={styles.bottomLeft} alt=" Bottom Left Background Shape" />
        <img src={BottomCenter}  data-scroll className={styles.bottomCenter} alt=" Bottom Center Background Shape" />
        <img src={BottomRight}  data-scroll className={styles.bottomRight} alt=" Bottom Right Background Shape" />
      </div>
    </div>
  )
}